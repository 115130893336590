import React from "react"
import "./Header.css"
import logo from "./images/logo_large-bgnd.png"


class Header extends React.Component {

  render() {
   return <div>

      <img alt="logo" src={logo} className={"logo"} />

    </div>
  }
}


export default Header