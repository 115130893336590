import gsap from "gsap"
import {SplitText} from "gsap/SplitText"

//Text Intro
export const newAnim = elem => {
 new SplitText(elem, {type: "chars, words", charsClass: "ok"})
};

export const moveAnim = elem => {
  gsap.from(elem, {
 x: 200,
 duration: 3,
 repeat: -1,
 yoyo: true
  })
};



// export const mysplit = elem => {
//   gsap.to(elem, {
//     y:50
//   })
// }