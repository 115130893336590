import React from "react"
import "./Header.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class Footer extends React.Component {

  render() {
   return <div>
      <div className={"socials"}>     
      <a className={"instaIcon"} href="https://www.instagram.com/kogamusic">       <FontAwesomeIcon style={{cursor: 'pointer'}}  icon={['fab', 'instagram']} /></a>
<a href="https://www.twitter.com/koga_music"><FontAwesomeIcon style={{cursor: 'pointer'}} className={"twitIcon"} icon={['fab', 'twitter']} /></a>
</div>

    </div>
  }
}


export default Footer