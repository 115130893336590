import React, { useRef, useEffect } from "react";
import Icons from "./Icons";
import { newAnim } from "./Animate";
import gsap from "gsap";
import SpotifyEmbed from "./SpotifyEmbed";

function Card(props) {
  let newAnimRef = useRef(null);

  useEffect(() => {
    newAnim(newAnimRef.current);
    var things = document.querySelectorAll(".ok");

    things.forEach((thing) => {
      thing.addEventListener("mouseenter", onMouseOver);
      thing.addEventListener("mouseleave", onMouseLeave);
    });
  }, []);

  function onMouseOver(event) {
    var trg = event.target;
    if (trg.tagName.toLowerCase() === "div") {
      gsap.to(trg, { rotate: 360 });
    }
  }

  function onMouseLeave(event) {
    var trg = event.target;
    if (trg.tagName.toLowerCase() === "div") {
      gsap.to(trg, { rotate: 0 });
    }
  }

  let mycss = "mytextsmall";

  if (props.imgside === "left") {
    return (
      <div className={"main"}>
        <img src={props.albumImage} alt="album" className={"album"} />
        <div className={"albumInfo"}>
          <p className={mycss} ref={newAnimRef}>
            {props.description}
          </p>
          <SpotifyEmbed embed={props.embed} />

          <Icons appleSrc={props.appleSrc} spotifySrc={props.spotifySrc} />
        </div>
      </div>
    );
  } else if (props.imgside === "right") {
    return (
      <div className={"main"}>
        <div className={"albumInfo"}>
          <p className={mycss} id={"albumtitle2"} ref={newAnimRef}>
            {props.description}
          </p>
          <SpotifyEmbed embed={props.embed} />
          <Icons appleSrc={props.appleSrc} spotifySrc={props.spotifySrc} />
        </div>
        <img src={props.albumImage} alt="album" className={"album"} />
      </div>
    );
  }
}

export default Card;
