import React from 'react';

function SpotifyEmbed(props) {


  return (
    <div>
      <iframe title="Player" className={"myFrame"} src={props.embed}frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    </div>
  );
}

export default SpotifyEmbed;


