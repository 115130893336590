import React from "react";
import Card from "./Card";
import Header from "./Header";
import album1 from "./images/album1.jpg"
import album2 from "./images/album2.png"
import album3 from "./images/album3.jpg"
import album4 from "./images/album4.jpg"
import "./Blocks.css"
import links from "./KogaLinks.json"
import Footer from "./Footer"



function App() {

console.log(links)

  return (
    <div>
      <Header />
      <Card  
        albumImage={album4} 
        description={"new to life"} 
        imgside="left" 
        spotifySrc={links[0].spotify} 
        appleSrc={links[0].apple} 
        embed={links[0].spotifyEmbed}
        />
      <Card albumImage={album3}  
      description={"bleeding money"} 
      spotifySrc={links[1].spotify} 
      appleSrc={links[1].apple}
      imgside="right"
      embed={links[1].spotifyEmbed}
       />
        <Card albumImage={album2}  
      description={"cold ocean"} 
      spotifySrc={links[2].spotify} 
      appleSrc={links[2].apple}
      imgside="left"
      embed={links[2].spotifyEmbed}
       />
        <Card albumImage={album1}  
      description={"not much is going on today"} 
      spotifySrc={links[3].spotify} 
      appleSrc={links[3].apple}
      imgside="right"
      embed={links[3].spotifyEmbed}
       />
           <Footer />
    </div>

  );
}

export default App;
