import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import gsap from "gsap"



function Icons(props) {

  useEffect(() => {
    var otherThings = document.querySelectorAll(".myicon")
otherThings.forEach(thing => {
thing.addEventListener('mouseenter', onMouseOveri)
thing.addEventListener('mouseleave', onMouseLeavei)
})
console.log(props)
  })


  function onMouseOveri(event) {
     
    gsap.to(event.target, {rotate: 360})
    gsap.to(event.target, {scale: "1.5"})
}

function onMouseLeavei(event) {
  gsap.to(event.target, {rotate: 0})
  gsap.to(event.target, {scale: "1"})
}

  return (
<div className={"icons"}>

<a href={props.spotifySrc}>
<FontAwesomeIcon style={{cursor: 'pointer'}} className={"spotifyicon myicon"} icon={['fab', 'spotify']} />
</a>

<a href={props.appleSrc}>
<FontAwesomeIcon style={{cursor: 'pointer'}} onClick={props.appleSrc} className={"appleicon myicon"} icon={['fab', 'apple']} />
</a>

</div>
  );
}

export default Icons;